import './App.css';
import React from "react";
import { useState, useEffect } from 'react';
import "./App.css";
import minh from "./Asset/minh_6.png"
import mark from"./Asset/mark.png"
import Typewriter from "typewriter-effect";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {

  const meta = {
    title: "Minh and Mark",
    description: "Welcome to my portfolio frontpage!",
  };

  const [expandedImage, setExpandedImage] = useState(2);
  useEffect(() => {
    const handleMouseMove = (event) => {
      if (event.pageX < ((window.innerWidth / 2) - (window.innerWidth / 6))) {
        setExpandedImage(0);
        } else if (event.pageX > ((window.innerWidth / 2) + (window.innerWidth / 6)) ) {
        setExpandedImage(1);
        } else {
        setExpandedImage(2);
        }
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <div 
        className= " flex h-full w-full mx-auto relative "
      >
        <a href="https://markducnguyen.com">
          <button
            className="sm:text-sm md:text-base absolute inset-0 w-full h-full"
            style={{
              backgroundSize: `cover`,
              backgroundPosition: `center`,
              backgroundImage: `url(${mark})`,
              transition: "all 1s ease-in-out",
              clipPath:
              expandedImage === 0
                ? "polygon(0 0, 100% 0, 100% 100%, 0 100%)"
                : expandedImage === 2
                  ? "polygon(0 0, 50% 0, 50% 100%, 0 100%)"
                  : "polygon(0 0, 0 0, 0 100%, 0 100%)"
            }}
          >
            <div
              className="absolute text-white top-0 left-0"
            >
              <h1 
                className='left flex w-1/2'
              >
                Mark <br/>Duc <br/> Nguyen 
              </h1>
              <div 
                className='description flex w-1/2'
                style={{margin: 'auto', maxWidth: `30%`,textAlign: `left`, wordWrap: `break-word`, direction: 'ltr',
                        marginLeft:`26px`, lineHeight: `100%`, marginTop:'30px', overflowWrap: `break-word`
                      }}
              >
                <Typewriter
                  options={{
                    strings: [
                      "I specialize in visual design with a strong focus on ideation I also love to illustrate animation elements to make each experience unique"
                    ],
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 10,
                  }}
              
                />
              </div>
            </div>
          </button>
        </a>
        <a href="https://minhtrannguyen.dev/">
          <button
            className='sm:text-sm md:text-base absolute  inset-0  w-full h-full'

            style={{
              backgroundSize: `cover`,
              backgroundPosition: `center`,
              backgroundImage: `url(${minh})`,
              transition: "all 1s ease-in-out",
              clipPath:
                expandedImage === 1
                  ? "polygon(0 0, 100% 0, 100% 100%, 0 100%)" 
                  : expandedImage === 2
                    ? "polygon(50% 0, 100% 0, 100% 100%, 50% 100%)"
                    : "polygon(100% 0, 100% 0, 100% 100%, 100% 100%)"
            }}
          >
            <div
              className="absolute top-0 right-0 text-white"
              style={{margin: `auto`}}
            >
              <h1 
                className='right flex w-1/2'
              >
                Minh <br/> Nguyen 
              </h1>
              <div 
                className='description flex w-1/2'
                style={{margin: 'auto', maxWidth: `30%`,textAlign: `right`, wordWrap: `break-word`, direction: 'rtl',
                        marginRight:`26px`, lineHeight: `100%`, marginTop:'30px', overflowWrap: `break-word`}}
              >
                <Typewriter
                  options={{
                    strings: [
                      " First step in solving a problem is recognizing there is one. My current actual problem is understanding and implementing Transformer models in Bioinformatics which is what I do every weekday, and in time series analysis which I have been doing every weekend"
                    ],
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 10,
                  }}
                        
                />
              </div>
            </div>
          </button>
        </a>

    </div>

  </HelmetProvider>

  );
}

export default App;
